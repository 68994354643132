import { GraphQLResult, GraphQLSubscription } from '@aws-amplify/api';
import {
  CaseAlertsQueryVariables,
  CaseAlertStatus,
  CaseHistoryItem,
  CaseHistoryQueryVariables,
  CaseHistoryResult,
  DeliveryMethod,
  Maybe,
  Order,
  OrderOriginatingSystem,
  OrderStatus,
  RelatedCasesInput,
  ShippingStatus,
  TechnicalPreferencesQueryVariables,
  WorkOrderAvailableSubscription,
} from 'API';
import classNames from 'classnames';
import { CaseStatusBadge } from 'components/common/CaseStatusBadge/CaseStatusBadge';
import ModuleContainer from 'components/common/ModuleContainer';
import { OrderProcessingNotification } from 'components/common/OrderProcessingNotification/OrderProcessingNotification';
import CaseAlerts from 'components/order-detail/CaseAlerts';
import CaseBundles from 'components/order-detail/CaseBundles/CaseBundles';
import CaseDeductions from 'components/order-detail/CaseDeductions';
import CaseFiles from 'components/order-detail/CaseFiles';
import CaseHistory from 'components/order-detail/CaseHistory/CaseHistory';
import CaseOverview from 'components/order-detail/CaseOverview/CaseOverview';
import CaseRelated from 'components/order-detail/CaseRelated/CaseRelated';
import CaseShipping from 'components/order-detail/CaseShipping/CaseShipping';
import OrderDetailHeader from 'components/order-detail/OrderDetailHeader/OrderDetailHeader';
import TabStrip from 'components/order-detail/TabStrip';
import { CaseDetailTab, getCaseDetailTabs } from 'configurations/CaseDetailConfiguration';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useAppConfig } from 'providers/AppProvider';
import { useAuth } from 'providers/AuthProvider';
import {
  CaseDetailModuleActionsContext,
  CaseDetailModuleContext,
  useCaseDetail,
} from 'providers/CaseDetailModuleProvider';
import { ShortcutKeysContext } from 'providers/ShortcutKeysProvider';
import { ToastContext } from 'providers/ToastProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCaseAlerts } from 'shared/api/case-alerts.api';
import { getCaseHistory } from 'shared/api/case-history.api';
import { invoiceAccountLookup } from 'shared/api/invoice.api';
import {
  getOrderFull,
  getRelatedCasesWithAddOnsAndServices,
  getShippingOrderTrackingNumbers,
  updateOrderStatus,
} from 'shared/api/order.api';
import { getTechnicalPreferences } from 'shared/api/technical-preferences.api';
import { subscribeToWorkOrder } from 'shared/api/workorder.api';
import { PrintMenu } from 'shared/constants/case-detail.constants';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ShortCutKeys } from 'shared/constants/shortcut-keys.constants';
import { OrderTypeName, ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { ACTIONS } from 'shared/enums/permission';
import { isLMSOrder, orderDisabled } from 'shared/helpers/order-detail/order-detail.helper';
import { printInvoice, printShippingLabel, printWorkOrder } from 'shared/helpers/printing/print.helper';
import { getUpdateCasePath } from 'shared/helpers/route.helper';
import { checkPriceOrTaxBeingCalculated } from 'shared/helpers/util.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { useSubscription } from 'shared/hooks/useSubscription';
import { CreatedOrder, TabConfig } from 'shared/models';
import { AnalyticsService } from 'shared/services/analytics.service';
import { checkCaseIsInvoiced, getSpiltBundleCaseInfo, isCreatedOrder, isPendingOrderData } from 'shared/utils';
import { CaseDetailStatusContent } from './CaseDetailStatusContent/CaseDetailStatusContent';

/**
 * Renders a tabbed page displaying various details of an order
 * presents following sections
 * OverView of a case - see {@link CaseOverview}
 * Files and Enclosed items - see {@link CaseFiles}
 * Shipping information - see {@link CaseShipping}
 * Outbound and inbound bundles - see {@link CaseBundles}
 * related cases - see {@link CaseRelated}
 * case history - see {@link CaseHistory}
 * deductions - see {@link CaseDeductions}
 * case alerts - see {@link CaseAlerts}
 */
const OrderDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: orderId } = useParams<{ id: string }>();
  const orderNumber = orderId || '';
  const [tabs, setTabs] = useState<TabConfig[]>(getCaseDetailTabs());
  const toast = useContext(ToastContext);
  const { sessionId } = useAppConfig();
  const { setCaseDetails, setCaseLoading, caseDetails: order } = useCaseDetail();

  const [caseHistoryItems, setCaseHistoryItems] = useState<CaseHistoryItem[]>([]);
  const { loading, error, data, initialized, refetch } = useQueryFetcher(getOrderFull, {
    orderNumber: orderNumber,
    skip: !orderNumber,
  });

  const { data: outboundData, loading: outboundLoading } = useQueryFetcher(getShippingOrderTrackingNumbers, {
    orderNumber: orderNumber,
    skip:
      !orderNumber || !data || (data.__typename !== OrderTypeName.PendingOrder && !isLMSOrder(data.originatingSystem)), // Don't fetch for legacy.
  });
  const billingAccountId = data?.billingAccountId || '';
  const { data: account } = useQueryFetcher(invoiceAccountLookup, {
    billingAccountId: billingAccountId,
    skip: !billingAccountId,
  });
  const { fetcher } = useLazyQueryFetcher(updateOrderStatus);
  const { fetcher: alertFetcher } = useLazyQueryFetcher(getCaseAlerts);
  const { fetcher: caseHistoryFetcher } = useLazyQueryFetcher(getCaseHistory);
  const { fetcher: getTechnicalPreferencesFetcher } = useLazyQueryFetcher(getTechnicalPreferences);
  /**
   * For an original case, we would like to return any orders that list this order as its original order number.
   * For cases that have an original order number set (currently RMA and new exchange), we would like to return
   * the original order, as well as any orders that have the same original order number.
   * NOTE: This may need to be adapted in the future to support multi-phase cases.
   */
  const relatedCasesQuery: RelatedCasesInput = useMemo(() => {
    const queryToUse: RelatedCasesInput = {
      originalOrderNumber: data?.orderNumber || '',
    };
    if (data?.originalOrderNumber) {
      const originalOrderNumber = data.originalOrderNumber;
      queryToUse.orderNumber = originalOrderNumber;
      queryToUse.originalOrderNumber = originalOrderNumber;
    }
    // Since the original order number is used to query for related RMA and new exchange orders, we will skip this query if it does not exist (meaning the data has not loaded yet).
    return {
      ...queryToUse,
      skip: !queryToUse.originalOrderNumber,
    };
  }, [data]);

  const {
    data: relatedCasesData,
    loading: relatedCasesLoading,
    error: relatedCasesError,
  } = useQueryFetcher(getRelatedCasesWithAddOnsAndServices, relatedCasesQuery);

  // Prevents us from displaying results for the same order number.
  const relatedCases = useMemo(() => {
    if (relatedCasesLoading || relatedCasesError) return [];
    return relatedCasesData?.orders.filter(relatedOrder => relatedOrder.orderNumber !== orderNumber) || [];
  }, [orderNumber, relatedCasesData?.orders, relatedCasesError, relatedCasesLoading]);

  const { user } = useAuth();
  const currentUserName = user?.displayName || '';
  const editCasePermission = useRoleBasedAccessControl(ACTIONS.EDIT_CASE);
  const printWorkOrderPermission = useRoleBasedAccessControl(ACTIONS.PRINT_WORKORDER);
  const alert = useContext(AlertModalContext);
  const { keyPressed, reset } = useContext(ShortcutKeysContext);
  const { caseAlerts } = useContext(CaseDetailModuleContext);
  const { setCaseAlerts, setTechnicalPreferences } = useContext(CaseDetailModuleActionsContext);

  useEffect(() => {
    setCaseLoading(loading);
  }, [loading, setCaseLoading]);

  const onRefreshOrder = useCallback(() => {
    refetch({ orderNumber: orderNumber });
  }, [refetch, orderNumber]);

  /**
   * Handles error messages from try/catch blocks.
   * If the error message is not a 404 and an error message is provided, shows it in a toast.
   * @param err - The error object.
   * @param toastMessage - An optional error message to show in a toaster.
   */
  const handleError = useCallback(
    (err: unknown, toastMessage?: string) => {
      const error = err as Error;
      if (error.name !== 'NotFoundError' && toastMessage) {
        toast.notify(toastMessage, ToastNotificationType.Error);
      }
    },
    [toast]
  );

  /**
   * Handles retrieving/setting case alerts.
   * @param billingAccountId - The billing account ID of the target order.
   * @param status - The status of the target order.
   */
  const handleGetCaseAlerts = useCallback(
    async (billingAccountId: Maybe<string>, status: OrderStatus) => {
      try {
        if (status === OrderStatus.Pending) throw new Error('Order is Pending');
        if (billingAccountId) {
          const fetchCaseAlertsRequest: CaseAlertsQueryVariables = {
            input: {
              billingAccountId,
            },
          };
          const caseAlertsResponse = await alertFetcher(fetchCaseAlertsRequest);
          // Only displays alerts that are applied to this particular order number.
          const appliedAlertsForThisOrder = caseAlertsResponse.caseAlerts.filter(
            caseAlert => caseAlert.status === CaseAlertStatus.Applied && caseAlert.orderNumber === orderNumber
          );
          setCaseAlerts(appliedAlertsForThisOrder);
        } else {
          setCaseAlerts([]);
        }
      } catch (err) {
        handleError(err);
        setCaseAlerts([]);
      }
    },
    [handleError, orderNumber, setCaseAlerts, alertFetcher]
  );

  /**
   * Handles retrieving/setting case history.
   * @param orderNumber - The number of the target order.
   */
  const handleGetCaseHistory = useCallback(
    async (orderNumber: string) => {
      try {
        if (orderNumber) {
          const fetchCaseAlertsRequest: CaseHistoryQueryVariables = {
            orderNumber,
          };
          const caseHistoryResponse: CaseHistoryResult = await caseHistoryFetcher(fetchCaseAlertsRequest);
          setCaseHistoryItems(caseHistoryResponse.caseHistoryItems);
        } else {
          setCaseHistoryItems([]);
        }
      } catch (err) {
        handleError(err, 'Error loading case history.');
        setCaseHistoryItems([]);
      }
    },
    [handleError, caseHistoryFetcher]
  );

  /**
   * Handles retrieving/setting technical preferences.
   * @param billingAccountId - The billing account ID of the target order.
   */
  const handleGetTechnicalPreferences = useCallback(
    async (billingAccountId: Order['billingAccountId'], providerId: Order['providerId']) => {
      try {
        if (billingAccountId && providerId) {
          const technicalPreferencesQuery: TechnicalPreferencesQueryVariables = {
            input: {
              billingAccountId,
              providerId,
            },
          };
          // Attempts to retrieve technical preferences for a given billing account and provider ID. If an error is returned, continues on with loading the rest of the order information.
          const preferencesByAccountAndProvider = await getTechnicalPreferencesFetcher(technicalPreferencesQuery);
          if (preferencesByAccountAndProvider) {
            setTechnicalPreferences(preferencesByAccountAndProvider.preferences);
          }
        }
      } catch (err) {
        handleError(err, 'Error loading technical preferences.');
      }
    },
    [handleError, setTechnicalPreferences, getTechnicalPreferencesFetcher]
  );

  /**
   * Loads secondary data that is not essential for the order detail page to be considered successfully rendered.
   * @param order - The target order for this case details page.
   */
  const loadSecondaryData = useCallback(
    (order: CreatedOrder) => {
      const { orderNumber, billingAccountId = '', providerId, status, originatingSystem } = order;
      if (originatingSystem && originatingSystem === OrderOriginatingSystem.Lms) {
        handleGetCaseAlerts(billingAccountId, status);
        handleGetCaseHistory(orderNumber);
      }
      handleGetTechnicalPreferences(billingAccountId, providerId);
    },
    [handleGetCaseAlerts, handleGetCaseHistory, handleGetTechnicalPreferences]
  );

  /**
   * Handles a case detail event by retrieving/setting technical preferences and setting case details.
   */
  const handleCaseDetailEvent = useCallback(async () => {
    if (loading || !initialized) return;

    if (error) {
      const errorMessage =
        error.name === 'NotFoundError'
          ? 'Case not found.'
          : 'There was an issue loading the product information. Please try again later.';
      toast.remove();
      return toast.notify(errorMessage, ToastNotificationType.Error, { duration: Infinity });
    }

    const orderResult = data;

    if (!orderResult) return toast.notify('Order is failed to load.', ToastNotificationType.Error);

    if (isPendingOrderData(orderResult)) {
      return toast.notify('This order is still pending', ToastNotificationType.Warning);
    } else {
      loadSecondaryData(orderResult);
      setCaseDetails(orderResult);
    }
  }, [loading, initialized, error, data, toast, loadSecondaryData, setCaseDetails]);

  useEffect(() => {
    handleCaseDetailEvent();
  }, [handleCaseDetailEvent]);

  const inboundBundle = useMemo(() => {
    if (!isCreatedOrder(data)) return;
    return data?.bundles?.inboundBundle;
  }, [data]);

  const outboundBundle = useMemo(() => {
    if (!isCreatedOrder(data)) return;
    return data?.bundles?.outboundBundle;
  }, [data]);

  const splitBundle = useMemo(() => {
    if (!isCreatedOrder(data)) return;
    return data?.bundles?.splitBundle;
  }, [data]);

  /**
   * computes badge count to be shown beside tab title
   * sets the new tab configuration
   */
  useEffect(() => {
    if (!order) return;
    setTabs(prevState => {
      return prevState.map(tab => {
        if (tab.title === CaseDetailTab.FilesAndItems) {
          tab.badgeCount = (order.fileAttachments?.length || 0) + (order.enclosedItems?.length || 0);
        } else if (tab.title === CaseDetailTab.Alerts) {
          tab.badgeCount = caseAlerts?.length || 0;
        } else if (tab.title === CaseDetailTab.Bundles) {
          const hasInbound = inboundBundle?.bundledOrderNumbers?.length;
          const hasOutbound = outboundBundle?.bundledOrderNumbers?.length;
          const hasSplit = splitBundle?.bundledOrderNumbers?.length;
          const hasSplitAndNotInvoiced = hasSplit && !checkCaseIsInvoiced(order.status);

          tab.badgeCount = hasInbound || hasOutbound || hasSplitAndNotInvoiced ? 1 : 0;

          if (hasInbound && (hasOutbound || hasSplitAndNotInvoiced)) {
            tab.badgeCount = 2;
          }
        } else if (tab.title === CaseDetailTab.RelatedCases) {
          tab.badgeCount = relatedCases.length;
        } else if (tab.title === CaseDetailTab.Shipping) {
          /**
           * Add shipping tab badge count.
           * Must take into account inbound, outbound, voided outbound, and doctor pickup.
           */
          tab.badgeCount =
            (order.shipping?.inboundTrackingNumbers?.length || 0) +
            (order.shipping?.outboundTrackingNumbers?.length || 0) +
            (outboundData ? outboundData.filter(data => data.status === ShippingStatus.Voided).length : 0) +
            (order?.shipping?.deliveryMethod === DeliveryMethod.DoctorPickup ? 1 : 0);
        }
        return tab;
      });
    });
  }, [
    caseAlerts?.length,
    splitBundle?.bundledOrderNumbers,
    inboundBundle?.bundledOrderNumbers,
    outboundBundle?.bundledOrderNumbers,
    outboundData,
    order,
    relatedCases.length,
  ]);

  /**
   * function to handle selection of tab
   * @param tab - selected tab
   */
  const tabSelectHandler = (tab: TabConfig) => {
    const resetTabs = tabs;
    resetTabs.forEach(t => (t.current = tab.title === t.title));
    AnalyticsService.track(AnalyticsEventName.CaseDetailsTabViewed, {
      caseNumber: orderNumber,
      tabName: tab.title,
    });
    setTabs([...resetTabs]);
  };

  /**
   * function to handle various print instructions
   * @see PrintMenu - for list of applicable print instructions
   */
  const onPrintMenuClickHandler = useCallback(
    async (menu: string, isOpenHtmlInWindow?: boolean) => {
      if (menu === PrintMenu.Invoice) {
        try {
          AnalyticsService.track(AnalyticsEventName.PrintInvoiceClicked, {
            caseNumber: orderNumber,
          });

          // TODO: Should have sub query on order to get invoice to pass in here.
          await printInvoice(orderId || '');
        } catch (e) {
          const error = e as Error;
          toast.notify(error.message || 'Error printing invoice.', ToastNotificationType.Error);
        }
      } else if (menu === PrintMenu.WorkOrder) {
        if (printWorkOrderPermission.allowRead) {
          try {
            AnalyticsService.track(AnalyticsEventName.PrintWorkOrderClicked, {
              caseNumber: orderNumber,
            });

            await printWorkOrder({ orderNumber: orderId || '', isOpenHtmlInWindow });
          } catch (e) {
            const error = e as Error;
            toast.notify(error.message || 'Error printing work order.', ToastNotificationType.Error);
          }
        } else {
          alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
        }
      } else if (menu === PrintMenu.ShippingLabel) {
        try {
          AnalyticsService.track(AnalyticsEventName.PrintShippingLabelClicked, {
            caseNumber: orderNumber,
          });

          await printShippingLabel(orderId || '');
        } catch (e) {
          const error = e as Error;
          toast.notify(error.message || 'Error printing shipping label.', ToastNotificationType.Error);
        }
      }
    },
    [alert, orderId, orderNumber, printWorkOrderPermission.allowRead, toast]
  );

  /**
   * On pressing Alt + P on keyboard, opens the work order HTML pop-up without initiating a print.
   */
  useEffect(() => {
    if (keyPressed === ShortCutKeys.AltP) {
      onPrintMenuClickHandler(PrintMenu.WorkOrder, true);
      // Resets the shortcut key context to allow for Alt-P command again.
      reset();
    }
  }, [onPrintMenuClickHandler, orderId, keyPressed, reset]);

  const [currentSelectedMenu, setCurrentSelectedMenu] = useState('');

  /**
   * function to handle user intention to edit existing case
   * if he is not authorized to edit, terminates further flow
   */
  const editCaseHandler = () => {
    if (editCasePermission.allowUpdate) {
      navigate({
        pathname: getUpdateCasePath(orderNumber),
      });
    } else {
      alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
    }
  };

  /**
   * function to handle restoration of case
   */
  const restoreCaseHandler = async () => {
    if (!orderNumber) return toast.notify('Invalid order number', ToastNotificationType.Error);
    try {
      await fetcher({ orderNumber: orderNumber, orderStatus: OrderStatus.Manufacturing, updatedBy: currentUserName });
      if (order) {
        setCaseDetails({ ...order, status: OrderStatus.Manufacturing });
        toast.notify(`Case has been restored`, ToastNotificationType.Success);
      }
    } catch (err) {
      const error = err as Error;
      toast.notify(error.message, ToastNotificationType.Error);
    }
  };

  const caseStatus = order?.status;

  const isOrderProcessing = useMemo(() => {
    return checkPriceOrTaxBeingCalculated(isCreatedOrder(order) ? order.metadata : undefined);
  }, [order]);

  const splitBundleCaseInfo = useMemo(() => {
    return getSpiltBundleCaseInfo(order);
  }, [order]);

  /**
   * Check if the order is being process
   * If yes, subscribe to workOrder and disable all options until
   * work order is available to be view so
   * when printing work order will display latest order information
   */
  const [isWorkOrderProcessing, setIsWorkOrderProcessing] = useState<boolean>(false);
  useSubscription(
    {
      onInitialize() {
        if (isOrderProcessing) {
          setIsWorkOrderProcessing(true);
          // Set a 30s timer for workOrder response, if no response comeback after 30
          // we will set isWorkOrderProcessing to false so user can interact with the order
          setTimeout(() => {
            setIsWorkOrderProcessing(false);
          }, 30000);
        }
        return subscribeToWorkOrder({
          OrderNumber: orderNumber,
          SessionId: sessionId,
        });
      },
      async onSuccess({ value }) {
        const { data } = value as GraphQLResult<GraphQLSubscription<WorkOrderAvailableSubscription>>;
        if (data?.workOrderAvailable) {
          setIsWorkOrderProcessing(false);
        }
      },
      onError({ error }) {
        console.warn('error: ', error);
        setIsWorkOrderProcessing(false);
      },
    },
    [isOrderProcessing, orderNumber, sessionId]
  );

  return (
    <div className="flex flex-col flex-1 relative">
      <CaseDetailStatusContent
        menuName={currentSelectedMenu}
        setMenuName={setCurrentSelectedMenu}
        orderNumber={orderNumber}
        originFacilityId={order?.originFacilityId}
        account={account}
      />
      {orderDisabled(caseStatus) && (
        <div
          className={classNames(
            {
              'z-50': !caseStatus,
              'z-10 mt-12': caseStatus,
            },
            'h-full absolute inset-0 bg-gray-50 opacity-60 cursor-not-allowed'
          )}
        ></div>
      )}
      <ModuleContainer>
        <OrderDetailHeader
          orderId={orderNumber}
          onPrintMenuClick={onPrintMenuClickHandler}
          onKebabMenuClick={setCurrentSelectedMenu}
          onEditCase={editCaseHandler}
          onRestoreCase={restoreCaseHandler}
          searchValue={location?.state?.searchValue ?? ''}
          onRefresh={onRefreshOrder}
          isOrderProcessing={isWorkOrderProcessing || isOrderProcessing}
        >
          {caseStatus && <CaseStatusBadge status={caseStatus} />}

          {isCreatedOrder(order) && (
            <div className="mt-3">
              <OrderProcessingNotification
                orderMetaData={order.metadata}
                orderNumber={order.orderNumber}
                onRefresh={onRefreshOrder}
              />
            </div>
          )}
          <div className="mt-6">
            <TabStrip tabs={tabs} onTabSelect={tabSelectHandler} />
          </div>
        </OrderDetailHeader>
        <div className="overflow-auto">
          <div className={`${tabs.find(t => t.current)?.title === CaseDetailTab.Overview ? '' : 'hidden'}`}>
            <CaseOverview tabSelectHandler={tabSelectHandler} account={account} />
          </div>
          <div className={`${tabs.find(t => t.current)?.title === CaseDetailTab.FilesAndItems ? '' : 'hidden'}`}>
            <CaseFiles fileAttachments={order?.fileAttachments} enclosedItems={order?.enclosedItems} />
          </div>
          <div className={`${tabs.find(t => t.current)?.title === CaseDetailTab.Shipping ? '' : 'hidden'}`}>
            <CaseShipping order={order} shippingData={outboundData || []} loading={loading || outboundLoading} />
          </div>
          <div className={`${tabs.find(t => t.current)?.title === CaseDetailTab.Bundles ? '' : 'hidden'}`}>
            <CaseBundles
              bundles={order?.bundles}
              orderNumber={orderNumber}
              caseStatus={caseStatus}
              bundleId={splitBundleCaseInfo.isBundle ? splitBundleCaseInfo.bundleId : null}
            />
          </div>
          <div className={`${tabs.find(t => t.current)?.title === CaseDetailTab.RelatedCases ? '' : 'hidden'}`}>
            <CaseRelated
              orders={relatedCases}
              orderNumber={orderNumber}
              originalOrderNumber={order?.originalOrderNumber}
            />
          </div>
          <div className={`${tabs.find(t => t.current)?.title === CaseDetailTab.CaseHistory ? '' : 'hidden'}`}>
            <CaseHistory caseHistoryItems={caseHistoryItems} />
          </div>
          <div className={`${tabs.find(t => t.current)?.title === CaseDetailTab.Deductions ? '' : 'hidden'}`}>
            <CaseDeductions
              discounts={order?.appliedDiscounts || []}
              credits={order?.appliedCredits || []}
              coupons={order?.appliedCoupons || []}
              orderStatus={caseStatus}
              orderRefresh={onRefreshOrder}
              isOrderProcessing={isOrderProcessing}
            />
          </div>
          <div className={`${tabs.find(t => t.current)?.title === CaseDetailTab.Alerts ? '' : 'hidden'}`}>
            <CaseAlerts caseAlerts={caseAlerts} />
          </div>
        </div>
      </ModuleContainer>
    </div>
  );
};

export default OrderDetailPage;
