import { ArrowSmallRightIcon } from '@heroicons/react/24/outline';
import { FormikValues, useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { OrderNumberRegex } from 'shared/constants/order.constants';
import { isFormikFieldInvalid } from 'shared/helpers/form.helper';
import * as Yup from 'yup';
import Input from '../Input/Input';
import InputError from '../InputError';
import Loader from '../Loader/Loader';
/**
 * Props for the OrderSearch component.
 */
interface OrderSearchProps {
  id: string;
  /**
   *
   * @param orderId - orderId to search
   * @returns  true if the order search is successful, false if the order search is unsuccessful, undefined if the order search is not performed
   */
  onOrderSearch: (orderId: string) => Promise<boolean | undefined>;
  label: string;
  onValueChange?: () => void;
  showProgress?: boolean;
  errorMessage?: string;
}

/**
 * Component for searching for an order.
 *
 * @param id - Unique identifier for the component.
 * @param label - Label for the input field.
 * @param showProgress - Indicates if the progress should be shown.
 * @param errorMessage - Error message to display.
 * @param onOrderSearch - Function to execute when searching for an order.
 * @param onValueChange - Function to execute when the input value changes.
 * @returns JSX.Element - The rendered component.
 */

const OrderSearch: React.FC<OrderSearchProps> = ({
  id,
  label,
  showProgress,
  errorMessage,
  onOrderSearch,
  onValueChange,
}) => {
  const orderIdRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (errorMessage && !showProgress) {
      orderIdRef?.current?.select();
    }
  }, [errorMessage, showProgress]);

  const submitHandler = async (data: FormikValues) => {
    const orderToSearch = data[id];
    /**
     * To avoid validation issue with extra long RMA cases (i.e. RMA10-2002108217),
     * Dizhen asked that we please not consider the order prefix during our length validation checks.
     */
    const abbreviatedOrderToSearch = orderToSearch?.split('-')?.[1] || orderToSearch;
    if (OrderNumberRegex.test(abbreviatedOrderToSearch)) {
      const isSuccessful = await onOrderSearch(orderToSearch);
      // only reset form if the order search is successful
      if (isSuccessful) {
        resetForm();
      }
    } else {
      setErrors({ [id]: 'Invalid Case #' });
    }
    orderIdRef.current?.select();
  };

  const OrderIdSchema = Yup.object().shape({
    [id]: Yup.string().required('Case # is required'),
  });

  const initialValues = {
    [id]: '',
  };

  const { values, errors, touched, submitCount, handleChange, handleBlur, handleSubmit, resetForm, setErrors } =
    useFormik({
      initialValues,
      validationSchema: OrderIdSchema,
      onSubmit: submitHandler,
      enableReinitialize: true,
      // Prevents error message from disappearing on blur
      validateOnBlur: false,
    });

  const isInvalid = () => {
    return !!errorMessage || isFormikFieldInvalid(!!touched[id], errors[id], submitCount);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label className="text-gray-700 text-sm font-medium block" htmlFor={id} data-testid={`${id}Label`}>
          {label}
        </label>
        <div className="flex gap-2 mt-1">
          <div className="w-56">
            <Input
              type="text"
              id={id}
              name={id}
              placeholder={`Scan or enter ${label.toLowerCase()}`}
              value={values[id]}
              onChange={e => {
                e.target.value = e.target.value.trim(); // trim the value
                handleChange(e);
                if (onValueChange) {
                  onValueChange();
                }
              }}
              onBlur={handleBlur}
              isInvalid={isInvalid()}
              data-qa={`${id}Input`}
              data-testid={`${id}Input`}
              ref={orderIdRef}
              disabled={showProgress}
              autoFocus
            />
            {isInvalid() && (
              <div data-testid="errorMessageLabel">
                <InputError message={errors[id] || errorMessage} />
              </div>
            )}
          </div>
          <button
            type="submit"
            className="border h-9 w-9 px-1 border-transparent rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            data-qa={`${id}Button`}
            data-testid={`${id}Button`}
            disabled={showProgress}
          >
            {showProgress ? (
              <Loader show={true} className="px-1 text-white" />
            ) : (
              <ArrowSmallRightIcon className="h-7" />
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default OrderSearch;
