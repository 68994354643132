import { OrderStatus } from 'API';
import DateInput from 'components/common/DateInput/DateInput';
import Input from 'components/common/Input/Input';
import InputError from 'components/common/InputError';
import Label from 'components/common/Label';
import { dateConfigurations } from 'configurations/DateFormatConfiguration';
import { useFormik } from 'formik';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { NEW_CASE } from 'shared/constants/constants';
import { getLocaleTimeZoneDateWithFormat } from 'shared/helpers/date.helper';
import { isFormikFieldInvalid } from 'shared/helpers/form.helper';
import { DateFormat } from 'shared/models/date-format';
import { formatPatientId, formatPatientName } from 'shared/utils';
import * as Yup from 'yup';

enum PatientInformation {
  PATIENT_FIRST_NAME = 'patientFirstName',
  PATIENT_LAST_NAME = 'patientLastName',
  PATIENT_ID = 'patientId',
}

/**
 * The PatientInfo component manages the display and validation of patient information fields.
 * It allows users to input patient names, IDs, and due dates.
 * @returns JSX element representing the PatientInfo component.
 */
const PatientInfo: React.FC = () => {
  const { orderLoading, order, noPatientInfoChecked, onSubmitAlertCount, dateFormat, orderStatus } =
    useContext(OrderModuleContext);

  const { patchOrder, setNoPatientInfoChecked, setDateFormat, resetCaseAlertSelections } =
    useContext(OrderModuleActionsContext);

  const { patientFirstName = '', patientId = '', patientLastName = '' } = order;

  useEffect(() => {
    const lab = '30'; // whatever we're going to use to get the current lab
    dateConfigurations.forEach((item: DateFormat) => {
      if (item.labId === lab) {
        setDateFormat(item.dateFormat);
      }
      return;
    });
  }, [setDateFormat]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formikChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
      setNoPatientInfoChecked(
        e.target.name === PatientInformation.PATIENT_FIRST_NAME ||
          e.target.name === PatientInformation.PATIENT_LAST_NAME ||
          e.target.name === PatientInformation.PATIENT_ID
          ? false
          : true
      );
      formikChangeHandler(e);
    },
    [setNoPatientInfoChecked]
  );

  const noPatientInfoChangeHandler = (checked: boolean) => {
    if (checked) {
      patchOrder({
        patientFirstName: '',
        patientLastName: '',
        patientId: '',
      });
    }
    setNoPatientInfoChecked(checked);
  };

  const patientInfoSchemaObj = {
    patientFirstName: Yup.string(),
    patientLastName: Yup.string(),
    patientId: Yup.string(),
    dueDate: Yup.date(),
  };

  const firstNameRequired = useMemo(() => {
    return (!noPatientInfoChecked && !order.patientLastName && !order.patientId) || !!order.patientFirstName;
  }, [order.patientFirstName, order.patientLastName, order.patientId, noPatientInfoChecked]);

  const lastNameRequired = useMemo(() => {
    return (!noPatientInfoChecked && !order.patientFirstName && !order.patientId) || !!order.patientLastName;
  }, [order.patientFirstName, order.patientLastName, order.patientId, noPatientInfoChecked]);

  const patientIdRequired = useMemo(() => {
    return (!noPatientInfoChecked && !order.patientFirstName && !order.patientLastName) || !!order.patientId;
  }, [order.patientFirstName, order.patientLastName, order.patientId, noPatientInfoChecked]);

  if (firstNameRequired) {
    patientInfoSchemaObj.patientFirstName = Yup.string().required('Patient First Name is required');
  }

  if (lastNameRequired) {
    patientInfoSchemaObj.patientLastName = Yup.string().required('Patient Last Name is required');
  }

  if (patientIdRequired) {
    patientInfoSchemaObj.patientId = Yup.string().required('Patient ID is required');
  }

  const PatientInfoSchema = Yup.object().shape(patientInfoSchemaObj);

  const customerDueDate = order.customerDueDate
    ? getLocaleTimeZoneDateWithFormat({ date: order.customerDueDate, format: dateFormat })
    : undefined;

  const { values, errors, touched, handleChange, handleBlur, validateForm, setFieldValue, setValues, handleSubmit } =
    useFormik({
      initialValues: {
        patientFirstName: patientFirstName || '',
        patientLastName: patientLastName || '',
        patientId: patientId || '',
        dueDate: customerDueDate,
      },
      validationSchema: PatientInfoSchema,
      onSubmit: () => {
        console.log();
      },
    });

  /**
   * Formats patient names and IDs so that they only include accepted characters.
   */
  const onBlurHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name: targetField, value: newValue } = e.target;
      // Formats patient names and patient IDs using different regular expressions.
      const isPatientName =
        targetField === PatientInformation.PATIENT_FIRST_NAME || targetField === PatientInformation.PATIENT_LAST_NAME;
      const formattedValue = isPatientName ? formatPatientName(newValue) : formatPatientId(newValue);
      // Sets the Formik field value and the order value to the same formatted value.
      setFieldValue(targetField, formattedValue);
      patchOrder({
        [targetField]: formattedValue,
      });
    },
    [patchOrder, setFieldValue]
  );

  useEffect(() => {
    if (onSubmitAlertCount > 0) {
      handleSubmit();
    }
  }, [onSubmitAlertCount, handleSubmit]);

  // We doing like this, because we want to keep the initial values of the patient info fields. So we are storing them in a ref.
  // So that we can use them to set the formik field values when the order is loaded.
  const initialPatientInfo = useRef({
    patientFirstName,
    patientLastName,
    patientId,
    customerDueDate,
  });

  /**
   * Sets the initial patient info values to the ref.
   * This is used to set the formik field values when the order is loaded.
   */
  useEffect(() => {
    initialPatientInfo.current.patientFirstName = patientFirstName?.trim() || '';
    initialPatientInfo.current.patientLastName = patientLastName?.trim() || '';
    initialPatientInfo.current.patientId = patientId?.trim() || '';
    initialPatientInfo.current.customerDueDate = customerDueDate;
  }, [patientFirstName, patientLastName, patientId, customerDueDate]);

  /**
   * sets the order values (patientFirstName, patientLastName, patientId, customerDueDate) to the formik field values.
   */
  useEffect(() => {
    if (orderLoading) return; // Prevents the formik field values from being set while the order is loading.
    const isNewCase = order.orderNumber === NEW_CASE; // Checks if the case is new.
    const isPendingCase = orderStatus === OrderStatus.Pending; // Checks if the case is in pending status.

    const { patientFirstName, patientLastName, patientId, customerDueDate } = initialPatientInfo.current;
    setValues(
      {
        patientFirstName: patientFirstName || '',
        patientLastName: patientLastName || '',
        patientId: patientId || '',
        dueDate: customerDueDate,
      },
      true
    );

    const isNoPatientInfoChecked = !patientFirstName && !patientLastName && !patientId;
    // If the case is new or orderStatus is "Pending", then no patient info checkbox will be unchecked on the initial load. Otherwise, it will be checked if there is no patient info.
    const checkNoPatientInfoChecked = isNewCase || isPendingCase ? false : isNoPatientInfoChecked;
    setNoPatientInfoChecked(checkNoPatientInfoChecked);
  }, [setFieldValue, order.orderNumber, orderLoading, orderStatus, validateForm, setNoPatientInfoChecked, setValues]);

  /**
   * Converts a target string to title-case.
   * @param stringToConvert - The string to convert to title-case.
   * @returns the target string in title-case.
   */
  const convertStringToTitleCase = (stringToConvert: string) => {
    return stringToConvert.charAt(0).toUpperCase() + stringToConvert.slice(1).toLowerCase();
  };

  /**
   * Converts a target string to name-format.
   * Names will be split at space and apostrophe characters and the first letter will be capitalized.
   * This is to comply with a request from the business in LMS1-7464.
   * @param nameToProcess - The target name to convert to name-format.
   * @returns the target name in name-format.
   */
  const convertStringToNameFormat = (nameToProcess: string) => {
    return nameToProcess
      .split(' ')
      .map(splitSpaceName => {
        return convertStringToTitleCase(splitSpaceName)
          .split("'")
          .map(splitApostropheName => {
            return splitApostropheName
              .split('-')
              .map(splitDashName => convertStringToTitleCase(splitDashName))
              .join('-');
          })
          .join("'");
      })
      .join(' ');
  };

  return (
    <>
      <div className="mt-6 grid grid-cols-4 gap-6">
        <div>
          <Input
            id="patientFirstName"
            name="patientFirstName"
            label="Patient First Name"
            value={values.patientFirstName}
            onChange={e => {
              e.target.value = convertStringToNameFormat(e.target.value);
              onInputChange(e, handleChange);
              setTimeout(() => {
                resetCaseAlertSelections({ patientFirstName: e.target.value });
                validateForm();
              });
            }}
            onBlur={e => {
              handleBlur(e);
              onBlurHandler(e);
            }}
            isInvalid={isFormikFieldInvalid(!!touched.patientFirstName, errors.patientFirstName)}
            isRequired={firstNameRequired}
            skeletonLoader={orderLoading}
          />
          <InputError message={errors.patientFirstName} forceHide={!touched.patientFirstName} />
        </div>
        <div>
          <Input
            id="patientLastName"
            name="patientLastName"
            label="Patient Last Name"
            value={values.patientLastName}
            onChange={e => {
              e.target.value = convertStringToNameFormat(e.target.value);
              onInputChange(e, handleChange);
              setTimeout(() => {
                resetCaseAlertSelections({ patientLastName: e.target.value });
                validateForm();
              });
            }}
            onBlur={e => {
              handleBlur(e);
              onBlurHandler(e);
            }}
            isInvalid={isFormikFieldInvalid(!!touched.patientLastName, errors.patientLastName)}
            isRequired={lastNameRequired}
            skeletonLoader={orderLoading}
          />
          <InputError message={errors.patientLastName} forceHide={!touched.patientLastName} />
        </div>
        <div>
          <Input
            id="patientId"
            name="patientId"
            label="Patient ID"
            value={values.patientId}
            onChange={e => {
              onInputChange(e, handleChange);
              setTimeout(() => {
                resetCaseAlertSelections({ patientId: e.target.value });
                validateForm();
              });
            }}
            onBlur={e => {
              handleBlur(e);
              onBlurHandler(e);
            }}
            isInvalid={isFormikFieldInvalid(!!touched.patientId, errors.patientId)}
            skeletonLoader={orderLoading}
            isRequired={patientIdRequired}
          />
          <InputError message={errors.patientId} forceHide={!touched.patientId} />
        </div>
        <div>
          <Label htmlFor="dueDate">Due Date</Label>
          <div className="relative w-full">
            <DateInput
              id="dueDate"
              placeholder={dateFormat.toUpperCase()}
              value={values.dueDate}
              onValueChange={value => {
                handleChange({ target: { name: 'dueDate', value } });
                patchOrder({
                  customerDueDate: value,
                });
              }}
              skeletonLoader={orderLoading}
              format={dateFormat}
            />
          </div>
        </div>
      </div>
      {!orderLoading && (
        <div className="mt-6 grid grid-cols-4 gap-6">
          <div id="no-patient-name-container">
            <Input
              id="noPatientInfo"
              name="noPatientInfo"
              type="checkbox"
              label="No Patient Info"
              value="true"
              onChange={e => {
                noPatientInfoChangeHandler(e.target.checked);
                if (e.target.checked) {
                  setFieldValue('patientFirstName', '');
                  setFieldValue('patientLastName', '');
                  setFieldValue('patientId', '');
                }
                setTimeout(() => {
                  resetCaseAlertSelections({ noPatientInfo: e.target.checked });
                  validateForm();
                });
              }}
              checked={noPatientInfoChecked}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PatientInfo;
