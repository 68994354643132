import SearchableDropdown from 'components/common/SearchableDropdown';
import { FC, useEffect, useRef } from 'react';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';

/**
 * Props for the ReasonForDiscount component.
 */
interface ReasonForDiscountProps {
  /**
   * List of reasons for discount.
   */
  reasons: string[];
}

/**
 * Component for selecting reason for discount.
 * @param reasons - List of reasons for discount.
 * @returns JSX.Element
 */
export const ReasonForDiscount: FC<ReasonForDiscountProps> = ({ reasons }) => {
  const discountReason = useAddDiscountModalStore(state => state.specialDiscount.reason);
  const setDiscountReason = useAddDiscountModalStore(state => state.specialDiscount.setReason);
  const mountedRef = useRef(false);
  /**
   * Set default selected discount reason if not selected once component is mounted.
   */
  useEffect(() => {
    if (discountReason.value || mountedRef.current) return;
    setDiscountReason({ value: '', error: false });
    mountedRef.current = true;
  }, [discountReason, reasons, setDiscountReason]);

  return (
    <div>
      <SearchableDropdown
        label="Reason for Discount"
        options={reasons}
        onSelectedChange={value => {
          setDiscountReason({ value, error: false });
        }}
        selected={discountReason.value}
        id="discountFor"
        isRequired
        dataQa="reason-for-discount"
        errorMessage={discountReason.error ? 'Please select a reason for discount' : ''}
        placeholder="Select"
      />
    </div>
  );
};
