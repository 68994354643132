import { AccountStanding, Address, AddressInput, Order, OrderStatus, Shipping, ShippingChargeType } from 'API';
import { getOriginFacilityMapping } from 'configurations/OriginFacilityConfiguration';
import { round } from 'lodash';
import { InvoiceAccount } from 'shared/api/invoice.api';
import { CancelActivity } from 'shared/constants/case-detail.constants';
import { INVALID_INVOICE_STATUSES } from 'shared/constants/invoice.constants';
import { ErrorMessage, ShippingService } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import {
  CannotInvoiceCallCenterError,
  CannotInvoiceCancelDiscardError,
  CannotInvoiceCreditHoldError,
  CannotInvoiceDiscountAmountError,
  CannotInvoiceMissingAlloyError,
  CannotInvoicePatientInfoError,
  CannotInvoiceStatusError,
} from 'shared/helpers/error-helper';
import { CreatedOrder, DropdownModel, LocalOrderInput, LocalOrderType } from 'shared/models';
import { AnalyticsService } from 'shared/services/analytics.service';
import { getCaseStatusRecord } from 'shared/utils';
import { getError } from './error-helper';
import { validateMetalAlloyAttributes } from './invoice/invoice.helper';
import { getShippingCharge } from './order-detail/order-detail.helper';

/**
 * destructs given column-sortOrder string to column and sortOrder
 *
 * @param sortableColumn - destructed column id and sort order
 */
export const getSortableOrder = (sortableColumn: string) => {
  const order: 'asc' | 'desc' = /^-.*/.test(sortableColumn) ? 'desc' : 'asc';
  return {
    id: sortableColumn.replace(/^-/, ''),
    order: order,
  };
};

/**
 * formats a given address object into user-friendly address
 *
 * @param address - user-friendly format
 */
export const getFormattedAddress = (address?: Address) => {
  if (!address) return '';

  let street = address.street1;
  if (address.street2) {
    street += ` ${address.street2}`;
  }
  if (street.trim().length) {
    return `${street}, ${address.city}, ${address.state ? `${address.state} ` : ''}${address.zipcode}`;
  }
  return '';
};

export const getShippingAddressOption = ({
  address,
  ...rest
}: {
  address: Address;
  secondaryLabel: string;
  secondaryLabelClassColor?: string;
  defaultColorClass?: string;
}): DropdownModel => {
  return {
    primaryLabel: getFormattedAddress(address),
    value: getFormattedAddress(address),
    ...rest,
  };
};

export const getFormattedOrderAddress = (shippingAddress: AddressInput) => {
  if (!shippingAddress) return '';
  return getFormattedAddress({
    ...shippingAddress,
    __typename: 'Address',
  });
};

/**
 *
 *
 * @param shippingCharges -
 * @param packageType -
 */
export const getSelectedShippingCharge = (
  shippingCharges: Shipping['shippingCharges'],
  packageType: Shipping['packageType']
) => {
  const charges = shippingCharges || [];
  const outboundShippingCharge = charges.find(charge => charge?.type === ShippingChargeType.Outbound);
  const isSignatureRequired = charges.some(charge => charge?.service === ShippingService.SIGNATURE_REQUIRED) || false;
  const isSaturdayDelivery = charges.some(charge => charge?.service === ShippingService.SATURDAY_DELIVERY) || false;

  return {
    carrier: outboundShippingCharge?.carrier || '',
    shippingService: outboundShippingCharge?.service || '',
    packageType: packageType || null,
    isSignatureRequired: isSignatureRequired,
    isSaturdayDelivery: isSaturdayDelivery,
    totalAmount: outboundShippingCharge?.totalAmount,
  };
};

/**
 * validates a case detail before invoicing
 *
 * @param caseDetail - case detail to be validated
 * @param account - invoice account to check if credit is on-hold
 */
export const validateCaseDetailForInvoicing = (
  caseDetail: CreatedOrder | undefined,
  account: InvoiceAccount | null
) => {
  if (!caseDetail) throw getError('Missing case details');

  let error: Error | undefined;

  const status = caseDetail.status;
  const statusReason = caseDetail.statusReason;
  const statusDisplayName = getCaseStatusRecord(status).displayName;

  if (status === OrderStatus.CallCenter) {
    error = new CannotInvoiceCallCenterError(ErrorMessage.FORWARD_CASE);
  } else if (INVALID_INVOICE_STATUSES.includes(status)) {
    error = new CannotInvoiceStatusError(`Case is ${statusDisplayName}`);
  }

  if (status === OrderStatus.Cancelled && statusReason === CancelActivity.Discard) {
    error = new CannotInvoiceCancelDiscardError(`Case is ${statusDisplayName}`);
  }

  if (!caseDetail.patientFirstName && !caseDetail.patientLastName && !caseDetail.patientId) {
    error = new CannotInvoicePatientInfoError();
  }

  if (!validateMetalAlloyAttributes(caseDetail.orderItems)) {
    error = new CannotInvoiceMissingAlloyError();
  }

  if (!account) {
    throw getError('Missing billing account info. Please contact support.');
  }

  const isAccountOnCreditHold = account.standing === AccountStanding.OnCreditHold;
  const isAccountAlwaysOnCreditHold = account.standing === AccountStanding.AlwaysOnCreditHold;

  if (isAccountOnCreditHold || isAccountAlwaysOnCreditHold) {
    error = new CannotInvoiceCreditHoldError();
  }

  const isValidDiscountAmount = checkTotalDiscountAmount(caseDetail);
  if (!isValidDiscountAmount) {
    error = new CannotInvoiceDiscountAmountError();
  }

  if (error) {
    AnalyticsService.track(AnalyticsEventName.InvoiceLoadedError, {
      errorName: error.name,
      errorMessage: error.message,
    });
    throw error;
  }
};

/**
 * checks if an order pricing/tax calculation in process
 *
 * @param orderMetaData - meta-data about order
 */
export const checkPriceOrTaxBeingCalculated = (orderMetaData: Order['metadata']) => {
  const isOrderProcessing = orderMetaData?.isPricingBeingCalculated || orderMetaData?.isTaxBeingCalculated;
  return !!isOrderProcessing;
};

/**
 * checks whether given order is an RMA order
 *
 * @param order - order to check
 */
export const isRmaLocalOrder = (order: LocalOrderInput) => {
  return order.localMetadata.localOrderType === LocalOrderType.RMA;
};

/**
 * Rounds a decimal number to the specified number of decimal places.
 * @param value - The number to round.
 * @param decimalPlaces - The number of decimal places to round to. Defaults to 2.
 * @returns The rounded number.
 *
 * @example
 *
 * ```ts
 * roundDecimal(1.2345); // 1
 * roundDecimal(1.2345, 2); // 1.23
 * roundDecimal(1.2345, 3); // 1.235
 * ```
 */
export const roundDecimal = (value: number, decimalPlaces = 2) => {
  const number = round(value, decimalPlaces);
  return isNaN(number) ? 0 : number;
};

/**
 * Checks if the total discount amount of the given orders is valid.
 *
 * @param orders - The orders to check the total discount amount for.
 * @returns A boolean indicating whether the total discount amount is valid.
 */
export const checkTotalDiscountAmount = (orders: Array<CreatedOrder> | CreatedOrder | undefined) => {
  if (!orders) return false;
  const items = Array.isArray(orders) ? orders : [orders];
  // Validates discount amount, if any order has a discount amount greater than the subtotal amount.
  const isValidDiscountAmount = items.every(item => {
    const totalDiscountAmount = item.totalDiscountAmount || 0;
    const subtotalAmount = item.subtotalAmount || 0;
    const shippingCharges = item.shipping?.shippingCharges || [];

    const inboundAmount = getShippingCharge(shippingCharges, ShippingChargeType.Inbound).amount;
    const outboundAmount = getShippingCharge(shippingCharges, ShippingChargeType.Outbound).amount;
    const finalSubTotalAmount = subtotalAmount + inboundAmount + outboundAmount;

    return totalDiscountAmount <= finalSubTotalAmount;
  });
  return isValidDiscountAmount;
};

/**
 * Removes the 'id' property from each item in the array.
 * @param items - The array of items.
 * @returns A new array with the 'id' property removed from each item.
 */
export const removeIdFromItems = <T extends { id: string }>(items: Array<T>) => items.map(({ id, ...rest }) => rest);

/**
 * Check and return new origin Facility if the originFacility
 * match the OriginFacilityMapping object set on config manager
 * @param originFacility - default originFacility string
 * @returns - a display originFacility string per config manager mapping or default originFacility string
 */
export const getOriginFacility = (originFacility: string): string => {
  const originFacilityMapping = getOriginFacilityMapping();
  return originFacilityMapping[originFacility] || originFacility;
};
