import { CreateCreditInput, Credit, OrderStatus } from 'API';
import { Table } from 'components/common/Table/Table';
import React, { useContext, useMemo, useState } from 'react';
import { CaseCreditItem, caseCreditColumns } from 'shared/constants/case-detail.constants';

import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { AddSpecialDiscountModal } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/AddSpecialDiscountModal';
import { DiscountModalType } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/types';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import moment from 'moment';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useAuth } from 'providers/AuthProvider';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { ToastContext } from 'providers/ToastProvider';
import { createCredits, deleteCredits } from 'shared/api/discount.api';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { ACTIONS } from 'shared/enums/permission';
import { convertCreditToItems } from 'shared/helpers/order-detail/order-detail.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { CreditType } from 'shared/models/discount';
import { AnalyticsService } from 'shared/services/analytics.service';
import { checkCaseIsInvoiced } from 'shared/utils';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';

/**
 * Props for the CaseCredits component.
 */
interface CaseCreditsProps {
  credits: Credit[]; // List of credits associated with the case
  orderStatus: OrderStatus | undefined; // Status of the order
  orderRefresh: () => void; // Function to refresh the order details
  isOrderProcessing: boolean; // Indicates whether the order is currently being processed
}

/**
 * A component for displaying and managing case credits.
 * This component allows users to view, add, and remove credits associated with a case.
 *
 * @param credits - List of credits associated with the case
 * @param orderStatus - Status of the order
 * @param orderRefresh - Function to refresh the order details
 * @param isOrderProcessing - Indicates whether the order is currently being processed
 * @returns JSX element representing the CaseCredits component
 */
const CaseCredits: React.FC<CaseCreditsProps> = ({ credits, orderStatus, orderRefresh, isOrderProcessing }) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [currentCredit, setCurrentCredit] = useState('');
  const alert = useContext(AlertModalContext);
  const newCredits = useAddDiscountModalStore(state => state.specialDiscount.discounts);
  const selectedDiscountNote = useAddDiscountModalStore(state => state.specialDiscount.note);
  const selectedDiscountReason = useAddDiscountModalStore(state => state.specialDiscount.reason.value);
  const selectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.selectedDiscountType);
  const setCurrentOrder = useAddDiscountModalStore(state => state.setCurrentOrder);
  const applyCreditPermission = useRoleBasedAccessControl(ACTIONS.APPLY_CREDIT);
  const { caseDetails: order } = useCaseDetail();
  const { user } = useAuth();
  const toast = useContext(ToastContext);
  const deadline = true; // TODO: find out what the restrictions on removing credits are
  const { fetcher: createCreditFetcher, loading: isCreditCreating } = useLazyQueryFetcher(createCredits);
  const { fetcher: deleteCreditsFetcher } = useLazyQueryFetcher(deleteCredits);

  /**
   * Columns configuration for the table.
   */
  const tableCols = [
    ...caseCreditColumns,
    {
      id: 'status',
      name: '',
      render: ({ row }: { row: CaseCreditItem }) => {
        return (
          <div className="flex" title={'Adjustments must be done in the accounting software.'}>
            <span className={`text-sm font-medium text-gray-800 bg-gray-100 rounded-full px-3 py-1`}>{row.status}</span>
            <InformationCircleIcon
              className="h-6 w-6 cursor-pointer mt-1 ml-1"
              aria-hidden="true"
              onClick={() => {
                if (!deadline) {
                  setCurrentCredit(row.creditNumber);
                  setShowConfirmModal(true);
                } else {
                  setShowAlertModal(true);
                }
              }}
            />
          </div>
        );
      },
    },
  ];

  /**
   * Function to handle the removal of a credit.
   * @param creditNumber - The credit number to be removed
   * @param updatedBy - The user who updated the credit
   */
  const handleDiscountRemove = async (creditNumber: string, updatedBy: string) => {
    try {
      await deleteCreditsFetcher({ creditNumber: creditNumber, input: { updatedBy } });

      AnalyticsService.track(AnalyticsEventName.CaseCreditRemoved, {
        caseNumber: order?.orderNumber || '',
      });
    } catch (ex) {
      console.error(ex);
      toast.notify(`Error removing credit.`, ToastNotificationType.Error);
    }
  };

  const onOpenModal = () => {
    if (applyCreditPermission.allowCreate) {
      setCurrentOrder(order);
      setShowModal(true);
    } else {
      alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
    }
  };

  const onCloseModal = () => {
    setCurrentOrder(null);
    setShowModal(false);
  };

  /**
   * Function to handle the application of a discount.
   */
  const handleDiscountApply = async () => {
    const newCredit: CreateCreditInput = {
      createdBy: user?.displayName || '',
      createdDate: new Date().toISOString(),
      caseDiscountType: selectedDiscountType,
      reason: selectedDiscountReason,
      creditItems: newCredits.map(item => {
        return {
          orderItemId: item.orderItemId,
          creditType: item.isPercentage ? CreditType.Percentage : CreditType.Dollar,
          productCode: item.productCode,
          value: item.percentageValue,
          description: item.description,
          discountAmount: item.dollarAmount,
        };
      }),
      notes: selectedDiscountNote,
      orderNumber: order?.orderNumber || '',
      utcConversionTimeZoneCode: moment.tz.guess(),
    };

    try {
      await createCreditFetcher(newCredit);

      AnalyticsService.track(AnalyticsEventName.CaseCreditAdded, {
        caseNumber: order?.orderNumber || '',
        discountType: selectedDiscountType,
        creditReason: selectedDiscountReason,
        creditTypes: newCredits.reduce((acc: string[], item) => {
          const deductionType = item.isPercentage ? CreditType.Percentage : CreditType.Dollar;
          if (acc.includes(deductionType)) return acc;
          return [...acc, deductionType];
        }, []),
      });

      orderRefresh();
      onCloseModal();
    } catch (ex) {
      console.error(ex);
      toast.notify(`Error creating credit.`, ToastNotificationType.Error);
    }
  };

  const creditRows = useMemo(() => convertCreditToItems(credits), [credits]);
  const caseIsInvoicePending = useMemo(() => order?.status === OrderStatus.InvoicePending, [order?.status]);

  return (
    <>
      <div>
        {checkCaseIsInvoiced(orderStatus) && (
          <>
            <div className="mb-6">
              <Table
                label="Credit"
                columns={tableCols}
                rows={creditRows}
                loading={false}
                pageSize={creditRows.length}
              />
            </div>
            <button
              type="button"
              className="text-sm py-2 w-36 text-center font-medium rounded-md text-gray-900 bg-white border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onOpenModal}
              data-qa={'dataQa'}
              disabled={isOrderProcessing || (order?.totalAmount ?? 0) <= 0 || caseIsInvoicePending}
            >
              + Add Credit
            </button>
          </>
        )}
      </div>
      <AddSpecialDiscountModal
        showModal={showModal}
        onClose={onCloseModal}
        onApply={handleDiscountApply}
        type={DiscountModalType.Credit}
        isLoading={isCreditCreating}
      />
      {showConfirmModal && (
        <DialogBox
          title="Are you sure you want to remove the credit?"
          onCancel={() => setShowConfirmModal(false)}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          confirmText="Remove"
          onConfirm={() => {
            handleDiscountRemove(currentCredit, user?.displayName || '');
            setShowConfirmModal(false);
          }}
        >
          <div className="text-base text-gray-500"></div>
        </DialogBox>
      )}
      {showAlertModal && (
        <DialogBox
          title="Adjustments must be done in the accounting software."
          onCancel={() => setShowAlertModal(false)}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          confirmButtonClassName={'hidden'}
        >
          <div className="text-base text-gray-500"></div>
        </DialogBox>
      )}
    </>
  );
};

export default CaseCredits;
