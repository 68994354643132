import { ShippingOption } from 'API';
import classNames from 'classnames';
import { AlertMessage } from 'components/common/Alert/AlertMessage';
import { OrderProcessingNotification } from 'components/common/OrderProcessingNotification/OrderProcessingNotification';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { ToastNotificationType } from 'shared/enums';
import { checkPriceOrTaxBeingCalculated } from 'shared/helpers/util.helper';
import { checkCaseIsInvoiced, getDateAndTimeInLongFormat, isAccountCreditHold } from 'shared/utils';
import { FetchShippingOptionsInput } from 'types/common';
import { InvoicedDiscountSection } from './InvoicedDiscountSection/InvoicedDiscountSection';
import { InvoicedShippingDetail } from './ShippingSection/InvoicedShippingDetail/InvoicedShippingDetail';
import { ShippingSection } from './ShippingSection/ShippingSection';
import { VerifyCaseSection } from './VerifyCaseSection/VerifyCaseSection';

/**
 * Props interface for the InvoiceDetail component.
 */
interface InvoiceDetailProps {
  /**
   * Boolean indicating whether order is loading.
   */
  isLoading: boolean;
  /**
   * Boolean indicating whether shipping is loading.
   */
  isShippingLoading: boolean;
  /**
   * Function to fetch shipping options.
   * @param data - The input data for fetching shipping options.
   * @returns A promise that resolves to an array of shipping options.
   */
  fetchShippingOptions: (data: FetchShippingOptionsInput) => Promise<ShippingOption[]>;
  /**
   * Optional function to refresh data.
   */
  onRefresh?: () => void;
}
/**
 * Component to display invoice details including case verification, shipping details, and discounts.
 * @param isLoading - Boolean indicating whether order is loading.
 * @param isShippingLoading - Boolean indicating whether shipping is loading.
 * @param fetchShippingOptions - Function to fetch shipping options.
 * @param onRefresh - Optional function to refresh data.
 */
const InvoiceDetail: React.FC<InvoiceDetailProps> = ({
  isLoading: invoicingLoading,
  isShippingLoading,
  fetchShippingOptions,
  onRefresh,
}) => {
  const { invoiceCases, account } = useInvoicingDetail();
  const firstInvoiceCase = invoiceCases[0];
  const isInvoicedCase = checkCaseIsInvoiced(firstInvoiceCase?.status);

  return (
    <div className="flex flex-col flex-grow mb-3">
      <div
        className={classNames('flex flex-col gap-3', {
          // Determine whether or not we are displaying an alert at the top and set padding accordingly.
          'p-6 pb-0 2xl:pb-6':
            !invoicingLoading &&
            (isAccountCreditHold(account?.standing) ||
              isInvoicedCase ||
              (firstInvoiceCase && checkPriceOrTaxBeingCalculated(firstInvoiceCase.metadata))),
        })}
      >
        {!invoicingLoading && isAccountCreditHold(account?.standing) && (
          <AlertMessage
            message={`This case cannot be invoiced because the account is on credit hold.`}
            type={ToastNotificationType.Error}
            className="w-full"
          />
        )}
        {!invoicingLoading && isInvoicedCase && (
          <AlertMessage
            message={`Invoiced on ${getDateAndTimeInLongFormat(
              firstInvoiceCase.invoiceDate,
              firstInvoiceCase.utcConversionTimeZoneCode
            )} ${firstInvoiceCase.invoiceBy ? `by ${firstInvoiceCase.invoiceBy}` : ''}`}
            type={ToastNotificationType.Warning}
            className="w-full"
          />
        )}
        {firstInvoiceCase && (
          <OrderProcessingNotification
            orderMetaData={firstInvoiceCase.metadata}
            orderNumber={firstInvoiceCase.orderNumber}
            onRefresh={onRefresh}
          />
        )}
      </div>
      <div className="flex-grow 2xl:flex">
        <div className="flex-grow 2xl:w-7/12">
          {/* Verify Case Information */}
          <div className="p-6">
            <VerifyCaseSection isLoading={invoicingLoading} isInvoicedCase={isInvoicedCase} />
          </div>
        </div>
        <div className="border-t border-gray-300 border-l-0 mx-0 2xl:border-t-0 2xl:border-l 2xl:w-5/12">
          {/* Select shipping */}
          <div className="p-6 2xl:flex-none" id="shipping-detail">
            {isInvoicedCase ? (
              <InvoicedShippingDetail isLoading={isShippingLoading} />
            ) : (
              <ShippingSection isLoading={isShippingLoading} fetchShippingOptions={fetchShippingOptions} />
            )}
          </div>
          <div className="border-gray-300 border-b"></div>
          {/* Discount */}
          <div className="p-6 2xl:flex-none" id="coupons">
            <InvoicedDiscountSection isLoading={invoicingLoading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetail;
