import {
  ForwardIcon,
  NoSymbolIcon,
  PauseCircleIcon,
  PencilSquareIcon,
  ReceiptRefundIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  CaseHistoryItem,
  Coupon,
  DiscountType,
  Order,
  OrderAttachment,
  OrderEnclosedItem,
  OrderItem,
  OrderStatus,
  ReturnType,
  SearchedOrderItem,
} from 'API';
import { CaseStatusBadge } from 'components/common/CaseStatusBadge/CaseStatusBadge';
import { TableViewFile } from 'components/common/Table/ViewFile';
import { TableColumn } from 'components/common/Table/types';
import { CaseHistoryAdditionalDetails } from 'components/order-detail/CaseHistory/AdditionalDetails/AdditionalDetails';
import { uniq } from 'lodash';
import { IManufacturingLocationContext } from 'providers/ManufacturingLocationProvider';
import { Link } from 'react-router-dom';
import { CouponStatusText } from 'shared/enums/coupon-status-text';
import { CaseHistoryEventType } from 'shared/enums/history-event-type';
import { formatDeductionAmount } from 'shared/helpers/coupon.helper';
import { extractMenuObjectFromArray } from 'shared/helpers/dropdown-menu.helper';
import {
  addOnsAndServicesText,
  firstTwoAttributes,
  uniqueDiscountItem,
} from 'shared/helpers/order-detail/order-detail.helper';
import { getOrderDetailPath } from 'shared/helpers/route.helper';
import {
  RelatedOrder,
  RelatedOrderItem,
  RelatedPendingOrder,
  RelatedRmaOrder,
  RelatedRmaOrderItem,
} from 'shared/models';
import { getDateAndTimeInShortFormat, getFormattedFileSize, getPatientFullName, isOrderData } from 'shared/utils';
import { DropdownMenu } from 'types/dropdown-menu';
import { caseInvoicedStatuses } from './invoice.constants';

const ON_HOLD_STATUSES = [OrderStatus.OnHold, OrderStatus.CallCenter];

const excludeCaseStatuses = (excludeStatuses?: OrderStatus[]) => {
  const orderStatuses = Object.values(OrderStatus);
  if (!excludeStatuses) return orderStatuses;
  return orderStatuses.filter(status => !excludeStatuses.includes(status));
};

export const printMenus = [
  {
    id: 'Invoice',
    name: 'Invoice',
    visibilities: caseInvoicedStatuses,
  },
  {
    id: 'WorkOrder',
    name: 'Work Order',
    visibilities: excludeCaseStatuses([OrderStatus.Processing]),
  },
  {
    id: 'DesignImages',
    name: 'Design Images',
    visibilities: excludeCaseStatuses([OrderStatus.Processing]),
  },
  {
    id: 'ShippingLabel',
    name: 'Shipping Label',
    visibilities: caseInvoicedStatuses,
  },
] as const;

export const PrintMenu = extractMenuObjectFromArray([...printMenus]);

export const documentTypes = [
  {
    id: 'Invoice',
    name: 'Invoice',
  },
  {
    id: 'Label',
    name: 'Label',
  },
] as const;

export const DocumentTypes = extractMenuObjectFromArray([...documentTypes]);

export enum KebabMenuType {
  AddToBundle = 'addToBundle',
  CreateRMA = 'createRMA',
  Invoice = 'invoice',
  UnInvoice = 'unInvoice',
  HoldCase = 'holdCase',
  CancelCase = 'cancelCase',
  DeleteCase = 'deleteCase',
  ContinueCase = 'continueCase',
}

export const kebabMenus = [
  // {
  //   id: KebabMenuType.CreateRMA,
  //   name: 'Create RMA',
  //   icon: <ReceiptRefundIcon className="w-5 h-5" />,
  //   visibilities: excludeCaseStatuses([OrderStatus.Invoiced]),
  // },
  {
    id: KebabMenuType.Invoice,
    name: 'Invoice',
    icon: <PencilSquareIcon className="w-5 h-5" />,
    visibilities: excludeCaseStatuses(caseInvoicedStatuses),
  },
  {
    id: KebabMenuType.UnInvoice,
    name: 'UnInvoice',
    icon: <PencilSquareIcon className="w-5 h-5" />,
    visibilities: caseInvoicedStatuses,
  },
  {
    id: KebabMenuType.HoldCase,
    name: 'Hold This Case',
    icon: <PauseCircleIcon className="w-5 h-5" />,
    visibilities: excludeCaseStatuses(ON_HOLD_STATUSES.concat(caseInvoicedStatuses)),
  },
  {
    id: KebabMenuType.ContinueCase,
    name: 'Continue Case',
    icon: <ForwardIcon className="w-5 h-5" />,
    visibilities: ON_HOLD_STATUSES,
  },
  {
    id: KebabMenuType.CancelCase,
    name: 'Cancel Case',
    icon: <NoSymbolIcon className="w-5 h-5" />,
    visibilities: excludeCaseStatuses(caseInvoicedStatuses),
  },
  {
    id: KebabMenuType.DeleteCase,
    name: 'Delete Case',
    icon: <TrashIcon className="w-5 h-5" />,
    className: 'text-red-500',
    visibilities: excludeCaseStatuses([
      OrderStatus.Invoiced,
      OrderStatus.InvoicePending,
      OrderStatus.Shipped,
      OrderStatus.Deleted,
      OrderStatus.Cancelled,
    ]),
  },
] as const;

export const kebabMenu = extractMenuObjectFromArray([...kebabMenus]);

export enum InvoiceMenuType {
  AdjustCodAmount = 'Adjust COD Amount',
}

export interface InvoiceMenuItemsI {
  isAbleToAdjustCod: boolean;
}

export const invoiceMenuItems = ({ isAbleToAdjustCod }: InvoiceMenuItemsI): DropdownMenu[] => {
  const menuToReturn = [];
  if (isAbleToAdjustCod) {
    menuToReturn.push({
      id: InvoiceMenuType.AdjustCodAmount,
      name: InvoiceMenuType.AdjustCodAmount,
      icon: <ReceiptRefundIcon className="w-5 h-5" />,
    });
  }
  return menuToReturn;
};

type OrderAttachmentWithTimezone = OrderAttachment & { utcConversionTimeZoneCode: Order['utcConversionTimeZoneCode'] };

export const fileColumns: TableColumn<OrderAttachmentWithTimezone>[] = [
  {
    id: 'name',
    name: 'File',
  },
  {
    id: 'size',
    name: 'File Size',
    render: ({ row }) => getFormattedFileSize(row.size || 0),
  },
  {
    id: 'createdDate',
    name: 'Added Date',
    render: ({ row }) => getDateAndTimeInShortFormat(row.createdDate, row.utcConversionTimeZoneCode),
  },
  {
    id: 'createdBy',
    name: 'Added By',
  },
  {
    id: 'fileUrl',
    name: '',
    render: ({ row }) => <TableViewFile fileUrl={row.fileUrl} label="View" fileName={row.name} />,
  },
];

export type OrderEnclosedItemWithTimezone = OrderEnclosedItem & {
  utcConversionTimeZoneCode: Order['utcConversionTimeZoneCode'];
};

export const inboundEnclosedColumns: TableColumn<OrderEnclosedItemWithTimezone>[] = [
  {
    id: 'itemCode',
    name: 'Item',
    className: 'w-88',
  },
  {
    id: 'quantity',
    name: 'Quantity',
  },
  {
    id: 'createdDate',
    name: 'Received Date',
    render: ({ row }) => getDateAndTimeInShortFormat(row.createdDate, row.utcConversionTimeZoneCode),
  },
  {
    id: 'createdBy',
    name: 'Added By',
  },
];

export const outboundEnclosedColumns: TableColumn<OrderEnclosedItemWithTimezone>[] = [
  {
    id: 'itemCode',
    name: 'Item',
    className: 'w-88',
  },
  {
    id: 'quantity',
    name: 'Quantity',
  },
  {
    id: 'createdDate', // According to services team, this is also the created date.
    name: 'Added Date',
    render: ({ row }) => getDateAndTimeInShortFormat(row.createdDate, row.utcConversionTimeZoneCode),
  },
  {
    id: 'createdBy',
    name: 'Added By',
  },
];

export enum CancelActivity {
  BackToCustomer = 'BackToCustomer',
  Discard = 'Discard',
}

export const cancelActivities = [
  {
    label: 'Send back to the customer',
    value: CancelActivity.BackToCustomer.toString(),
  },
  {
    label: 'Discard',
    value: CancelActivity.Discard.toString(),
  },
];

interface RelatedCaseProductInformationI {
  orderItems: OrderItem[] | RelatedRmaOrderItem[] | RelatedOrderItem[];
}

/**
 * Returns product information for the related cases.
 * @param orderItems - The order items to list product information for.
 * @returns product information for the related cases.
 */
const RelatedCaseProductInformation = ({ orderItems = [] }: RelatedCaseProductInformationI) => {
  return (
    <>
      {orderItems.map((item, index) => {
        const { addOns, services, productName } = item;
        return (
          <div key={`${productName}-${index}-orderItems`} className="mb-2">
            <span className="text-gray-900 text-md font-semibold">{productName}&nbsp;</span>
            <span className="text-gray-500">{firstTwoAttributes(item)}</span>
            <div className="text-gray-500">{addOnsAndServicesText(addOns, services)}</div>
          </div>
        );
      })}
    </>
  );
};

export const relatedMPCaseTableColumns: TableColumn<RelatedOrder | RelatedPendingOrder>[] = [
  {
    id: 'orderNumber',
    name: 'CASE #',
    render({ row }) {
      const orderNumber = row.orderNumber;
      return (
        <Link
          to={getOrderDetailPath(orderNumber)}
          className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex"
        >
          {orderNumber}
        </Link>
      );
    },
  },
  {
    id: 'orderItems',
    name: 'PRODUCT',
    render({ row }) {
      if (isOrderData(row)) {
        return <RelatedCaseProductInformation orderItems={row.orderItems} />;
      }
    },
  },
  {
    id: 'step',
    name: 'PHASE',
  },
  {
    id: 'other',
    name: 'OTHER SERVICES',
  },
  {
    id: 'recieved',
    name: 'RECEIVED DATE',
  },
  {
    id: 'status',
    name: 'CASE STATUS',
    render({ row }) {
      return <CaseStatusBadge status={row.status} testId={`${row.orderNumber}-status`} />;
    },
  },
];

export const relatedRMACaseTableColumns: TableColumn<RelatedRmaOrder>[] = [
  {
    id: 'orderNumber',
    name: 'CASE #',
    render({ row }) {
      const orderNumber = row.orderNumber;
      return (
        <Link
          to={getOrderDetailPath(orderNumber)}
          className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex"
        >
          {orderNumber}
        </Link>
      );
    },
  },
  {
    id: 'orderItems',
    name: 'PRODUCT',
    render({ row }) {
      return <RelatedCaseProductInformation orderItems={row.orderItems} />;
    },
  },
  {
    id: 'step',
    name: 'RETURN TYPE',
    render({ row }) {
      const { orderItems } = row;
      return (
        <>
          {orderItems.map((item, index) => (
            <div key={`${item.productName}-${index}-returnType`} className="mb-2">
              {item.returnType}&nbsp;
              {/* adding this below lines with opacity-0 to maintain the styling as addons and services length may different for different orderItems */}
              <span className="text-gray-500 opacity-0 pointer-events-none">{firstTwoAttributes(item)}</span>
              <div className="text-gray-500 opacity-0 pointer-events-none">
                {addOnsAndServicesText(item.addOns, item.services)}
              </div>
            </div>
          ))}
        </>
      );
    },
  },
  {
    id: 'other',
    name: 'REASON',
    render({ row }) {
      const { orderItems } = row;
      return (
        <>
          {orderItems.map((item, index) => (
            <div key={`${item.productName}-${index}-returnType`} className="mb-2">
              {item.returnReasons?.join(', ')}&nbsp;
              {/* adding this below lines with opacity-0 to maintain the styling as addons and services length may different for different orderItems */}
              <span className="text-gray-500 opacity-0 pointer-events-none">{firstTwoAttributes(item)}</span>
              <div className="text-gray-500 opacity-0  pointer-events-none">
                {addOnsAndServicesText(item.addOns, item.services)}
              </div>
            </div>
          ))}
        </>
      );
    },
  },
  {
    id: 'labReceivedDate',
    name: 'RECEIVED DATE',
    render: ({ row }) =>
      getDateAndTimeInShortFormat(row.labReceivedDate || '', row?.utcConversionTimeZoneCode || '') || 'N/A',
  },
  {
    id: 'invoicedDate',
    name: 'INVOICED DATE',
    render: ({ row }) =>
      getDateAndTimeInShortFormat(row.invoiceDate || '', row?.utcConversionTimeZoneCode || '') || 'N/A',
  },
  {
    id: 'status',
    name: 'CASE STATUS',
    render({ row }) {
      return <CaseStatusBadge status={row.status} testId={`${row.orderNumber}-status`} />;
    },
  },
];

export const relatedOriginalCaseTableColumns: TableColumn<RelatedOrder | RelatedRmaOrder>[] = [
  {
    id: 'orderNumber',
    name: 'CASE #',
    render({ row }) {
      const orderNumber = row.orderNumber;
      return (
        <Link
          to={getOrderDetailPath(orderNumber)}
          className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex"
        >
          {orderNumber}
        </Link>
      );
    },
  },
  {
    id: 'orderItems',
    name: 'PRODUCT',
    render({ row }) {
      return <RelatedCaseProductInformation orderItems={row.orderItems} />;
    },
  },
  {
    id: '',
    name: 'PATIENT NAME/ID',
    render({ row }) {
      const { patientFirstName, patientLastName, patientId } = row;
      return getPatientFullName(patientFirstName, patientLastName) || patientId || 'Not Provided';
    },
  },
  {
    id: 'providerName',
    name: 'PROVIDER NAME',
    render({ row }) {
      const { providerName } = row;
      return providerName || 'N/A';
    },
  },
  {
    id: 'labReceivedDate',
    name: 'RECEIVED DATE',
    render: ({ row }) =>
      getDateAndTimeInShortFormat(row.labReceivedDate || '', row?.utcConversionTimeZoneCode || '') || 'N/A',
  },
  {
    id: 'invoicedDate',
    name: 'INVOICED DATE',
    render: ({ row }) =>
      getDateAndTimeInShortFormat(row.invoiceDate || '', row?.utcConversionTimeZoneCode || '') || 'N/A',
  },
];

export const relatedOtherCaseTableColumns: TableColumn<RelatedOrder | RelatedPendingOrder>[] = [
  {
    id: 'orderNumber',
    name: 'CASE #',
    render({ row }) {
      const orderNumber = row.orderNumber;
      return (
        <Link
          to={getOrderDetailPath(orderNumber)}
          className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex"
        >
          {orderNumber}
        </Link>
      );
    },
  },
  {
    id: 'orderItems',
    name: 'PRODUCT',
    render({ row }) {
      if (isOrderData(row)) {
        return <RelatedCaseProductInformation orderItems={row.orderItems} />;
      }
    },
  },
  {
    id: 'step',
    name: 'LINK TYPE', // NOTE: Need a way to identify how cases are linked.
  },
  {
    id: 'originatingSystem',
    name: 'SOURCE TYPE', // NOTE: Need external source system indexed in Open Search. Using originating system for now.
  },
  {
    id: 'labReceivedDate',
    name: 'RECEIVED DATE',
    render: ({ row }) =>
      isOrderData(row)
        ? getDateAndTimeInShortFormat(row.labReceivedDate || '', row?.utcConversionTimeZoneCode || '') || 'N/A'
        : 'N/A',
  },
  {
    id: 'status',
    name: 'CASE STATUS',
    render({ row }) {
      return <CaseStatusBadge status={row.status} testId={`${row.orderNumber}-status`} />;
    },
  },
];

export const returnTypeColors = {
  [ReturnType.Remake]: 'purple',
  [ReturnType.Adjust]: 'yellow',
  [ReturnType.Exchange]: 'blue',
  [ReturnType.ReturnForCredit]: 'red',
};

export interface CaseHistoryAdditionalDetailsOrderItemAttribute {
  Name: string;
  Value: string;
  Type: string;
  UnitPrice: string;
  Quantity: string;
}
export interface CaseHistoryAdditionalDetailsAttachmentsAttribute {
  Name: string;
}
export interface CaseHistoryAdditionalDetailsOrderItem {
  ProductCode: string;
  ReturnType?: string;
  ReturnReasons?: string[];
  Attributes: CaseHistoryAdditionalDetailsOrderItemAttribute[];
  Services: CaseHistoryAdditionalDetailsOrderItemAttribute[];
  AddOns: CaseHistoryAdditionalDetailsOrderItemAttribute[];
}

export interface CaseHistoryAdditionalDetailsTracking {
  TrackingNumber: string;
}

export interface CaseHistoryAdditionalDetailsShipping {
  OutboundTrackingNumbers: CaseHistoryAdditionalDetailsTracking[];
  InboundTrackingNumbers: CaseHistoryAdditionalDetailsTracking[];
  [x: string]: unknown;
}

export interface CaseHistoryAdditionalDetailsObject {
  OrderItems?: CaseHistoryAdditionalDetailsOrderItem[];
  Status?: string;
  StatusReason?: string;
  Attachment?: CaseHistoryAdditionalDetailsAttachmentsAttribute[];
  Default?: object[];
  Shipping?: CaseHistoryAdditionalDetailsShipping;
  ShippingPreference?: object[];
  TechnicalPreference?: object[];
  ManufacturingStatus?: string;
  BundleId?: string;
  BundleOrderNumbers?: string;
  DetachedOrderNumbers?: string;
  [x: string]: unknown;
}

export type CaseHistoryPreferenceObjectData =
  | CaseHistoryAdditionalDetailsObject['Default']
  | CaseHistoryAdditionalDetailsObject['ShippingPreference']
  | CaseHistoryAdditionalDetailsObject['TechnicalPreference'];

export interface CaseHistoryPreferenceObject {
  oldData: CaseHistoryPreferenceObjectData;
  newData: CaseHistoryPreferenceObjectData;
  name: string;
}

export type LocalCaseHistoryItem = CaseHistoryItem & {
  eventType: CaseHistoryEventType;
};

export const caseHistoryColumns = (
  getManufacturingLocationFunc: IManufacturingLocationContext['getManufacturingLocation']
): TableColumn<LocalCaseHistoryItem>[] => {
  return [
    {
      id: 'department',
      name: 'Department',
      render: ({ row }) => {
        const departments = row.products?.map(product => product.department).filter(Boolean);
        return uniq(departments).join(',') || ''; // Defining a render property to default to empty string instead of the default N/A
      },
    },
    {
      id: 'eventType',
      name: 'Event',
      render: ({ row }) => getCaseHistoryEventTypeDisplay(row.eventType || ''), // Defining a render property to default to empty string instead of the default N/A
    },
    {
      id: 'locationId',
      name: 'Location',
      render: ({ row }) =>
        getManufacturingLocationFunc(parseInt(row.locationId || '0', 10))?.locationName || row.locationId,
    },
    {
      id: 'additionalDetails',
      name: 'Additional Details',
      render: ({ column, row }) => {
        return <CaseHistoryAdditionalDetails row={row} id={`${column.id}-${row.timeStamp}`} />;
      },
    },
    {
      id: 'completedBy',
      name: 'Completed By',
      render: ({ row }) => {
        const { completedBy, completedByTechId } = row;
        return (
          <div>
            <div className="mb-1">{completedBy}</div>
            {completedByTechId && <div>({completedByTechId})</div>}
          </div>
        );
      },
    },
    {
      id: 'timeStamp',
      name: 'Timestamp',
      render: ({ row }) =>
        (row.timeStamp && getDateAndTimeInShortFormat(row.timeStamp, row?.utcConversionTimeZoneCode || '')) || '',
    },
  ];
};

export type RelatedCase = {
  orderNumber: string;
  orderItems: SearchedOrderItem[];
  step: SearchedOrderItem[];
  other: SearchedOrderItem[];
  recieved: string;
  status: OrderStatus;
};

export type CaseDeductionItem = {
  discountId: string;
  orderItemId: string;
  // description is the unique key for the discount item
  description: string;
  amount: string;
  reason: string;
  notes: string;
  addedBy: string;
  dateApplied: string;
};

export const caseDeductionColumns: TableColumn<CaseDeductionItem>[] = [
  {
    id: 'description',
    name: 'Deduction For',
    render: ({ row }) => {
      const { description, orderItemId } = row;
      return <div>{uniqueDiscountItem(description, orderItemId)}</div>;
    },
  },
  {
    id: 'amount',
    name: 'Amount',
  },
  {
    id: 'reason',
    name: 'Reason',
  },
  {
    id: 'notes',
    name: 'Notes',
  },
  {
    id: 'addedBy',
    name: 'Added By',
  },
  {
    id: 'dateApplied',
    name: 'Date Applied',
  },
];

export type CaseCreditItem = {
  amount: string;
  reason: string;
  notes: string;
  creditNumber: string;
  createdBy: string;
  createdDate: string;
  status: string;
};

export const caseCreditColumns: TableColumn<CaseCreditItem>[] = [
  {
    id: 'creditNumber',
    name: 'Credit Number',
  },
  {
    id: 'amount',
    name: 'Amount',
  },
  {
    id: 'reason',
    name: 'Reason',
  },
  {
    id: 'notes',
    name: 'Notes',
  },
  {
    id: 'createdBy',
    name: 'Added By',
  },
  {
    id: 'createdDate',
    name: 'Date Applied',
  },
];

export type CaseCouponItem = {
  code: Coupon['code'];
  createdBy: Coupon['createdBy'];
  createdDate: Coupon['createdDate'];
  discountAmount: number;
  discountType: DiscountType;
  reason: string;
  status: Coupon['status'];
  utcConversionTimeZoneCode?: string;
};

export const caseCouponColumns: TableColumn<CaseCouponItem>[] = [
  {
    id: 'code',
    name: 'Coupon',
  },
  {
    id: 'amount',
    name: 'Amount',
    render: ({ row }) => {
      const { discountAmount, discountType } = row;
      return formatDeductionAmount(discountAmount, discountType);
    },
  },
  {
    id: 'reason',
    name: 'Reason',
  },
  {
    id: 'status',
    name: 'Status',
    render: ({ row }) => {
      const { status } = row;
      return status ? CouponStatusText[status] : 'N/A';
    },
  },
  {
    id: 'createdBy',
    name: 'Added By',
    render: ({ row }) => {
      const { createdBy } = row;
      return createdBy || 'N/A';
    },
  },
  {
    id: 'createdDate',
    name: 'Date Applied',
    render: ({ row }) => {
      const { createdDate, utcConversionTimeZoneCode } = row;
      return createdDate ? getDateAndTimeInShortFormat(createdDate, utcConversionTimeZoneCode) : 'N/A';
    },
  },
];

export const MANAGER_WORK_ITEM_EVENTS = [
  CaseHistoryEventType.ManagerWorkItemCreated,
  CaseHistoryEventType.ManagerWorkItemResolved,
  CaseHistoryEventType.ManagerWorkItemUpdated,
  CaseHistoryEventType.TechnicianProcessUpdated,
];

export const SPLIT_BUNDLE_ORDER_EVENTS = [CaseHistoryEventType.SplitBundleOrderRemoved];

export const TECHNICIAN_EVENTS = [
  CaseHistoryEventType.ManagerProcessUpdated,
  CaseHistoryEventType.TechnicianTaskRecorder,
];

export const ROUTING_EVENTS = [CaseHistoryEventType.LocationScan, CaseHistoryEventType.LocationScanCheckIn];

export const TECHNICIAN_AND_ROUTING_EVENTS = [...TECHNICIAN_EVENTS, ...ROUTING_EVENTS];

export const SPECIAL_DISCOUNT_EVENTS = [
  CaseHistoryEventType.SpecialDiscountCreated,
  CaseHistoryEventType.SpecialDiscountDeleted,
];

export const CaseHistoryEventTypeDisplay: Record<CaseHistoryEventType, string> = {
  [CaseHistoryEventType.Created]: 'Created',
  [CaseHistoryEventType.OrderTransactionCreated]: 'Case Created',
  [CaseHistoryEventType.OrderTransactionUpdated]: 'Case Updated',
  [CaseHistoryEventType.StatusUpdate]: 'Status Updated',
  [CaseHistoryEventType.InvoicePending]: 'Invoice Pending',
  [CaseHistoryEventType.Invoiced]: 'Invoiced',
  [CaseHistoryEventType.PreferenceOverride]: 'Preference Override',
  [CaseHistoryEventType.InvoiceVoided]: 'Invoice Voided',
  [CaseHistoryEventType.LocationScan]: 'Location Scan',
  [CaseHistoryEventType.LocationScanCheckIn]: 'Location Scan Check In',
  [CaseHistoryEventType.TechnicianTaskRecorder]: 'Technician Task',
  [CaseHistoryEventType.TechnicianProcessUpdated]: 'Manager Process Updated',
  [CaseHistoryEventType.ManagerProcessUpdated]: 'Manager Process Updated',
  [CaseHistoryEventType.ManagerWorkItemResolved]: 'Manager Work Item Resolved',
  [CaseHistoryEventType.ManagerWorkItemCreated]: 'Manager Work Item Created',
  [CaseHistoryEventType.ManagerWorkItemUpdated]: 'Manager Work Item Updated',
  [CaseHistoryEventType.SplitBundleOrderRemoved]: 'Split Bundle Order Removed',
  [CaseHistoryEventType.SpecialDiscountCreated]: 'Special Discount Created',
  [CaseHistoryEventType.SpecialDiscountDeleted]: 'Special Discount Deleted',
  [CaseHistoryEventType.CouponApplied]: 'Coupon Added',
  [CaseHistoryEventType.CouponDeleted]: 'Coupon Removed',
};

/**
 * Retrieves the display value for a given case history event type.
 * If the display value is not found, the event type itself is returned.
 *
 * @param eventType - The case history event type.
 * @returns The display value for the given event type.
 */
export const getCaseHistoryEventTypeDisplay = (eventType: CaseHistoryEventType) => {
  return CaseHistoryEventTypeDisplay[eventType] || eventType;
};
